.Player{
    grid-row: 2;
    grid-column: 2;
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
    align-self: flex-end;
    justify-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: blink 0.8s linear ;
    z-index: 10;

}

.alert{
    background: url('../../../Assets/Alert.png');
    background-size: cover;
    background-position: center;
}
@keyframes jump {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0%); ;
    }
    40% {
        transform: translateY(-20%); ;
    }

}
.jump {
    animation: jump 0.8s;
}
.Player canvas {
    width: 100%;
    height: 100%;
    align-self: flex-end;
    justify-self: flex-end;
}
.Seguradoras {

    filter: drop-shadow(0px 0px  2px rgb(0, 0, 0));

    grid-column-start: 2;
    grid-row-start: 2;

    z-index: 10;

    width:100%;
    height: 100%;
    justify-self: flex-end;
    align-self: flex-end;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
    display: flex;
    animation: blink 0.8s linear ;
}


.Seguradoras canvas {
    z-index: 10;

    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    background: none;
    background-size: cover;
    align-self: flex-end;
    justify-self: flex-end;
    background-position: center;
}
.Segurador {
    z-index: 10;

    grid-column-start: 1;
  
    grid-row-start: 2;
    align-self: flex-end;
    width: 100%;
    height: 100%;
}
.direita{
    
    grid-column-start: 3;
    grid-row-start: 2;

}
.Segurador canvas {
    mix-blend-mode: multiply;

    filter: drop-shadow(0px 0px  2px rgb(0, 0, 0));
    opacity: 1;
    width: 100%;
    height: 100%;
    background: none;
    background-size: cover;
    align-self: flex-end;
    justify-self: flex-end;
    background-position: center;
}