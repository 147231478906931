.paginaSelecao {
    position: absolute;


    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 100vh;   
    background-color: white;
    z-index: 20;
    margin: 0;
    top: 0;
    padding: 0%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.paginaSelecao header{
    width: 100%;
    height: 80px;
    background-color: #85e1fd;

    border-bottom: 8px solid black;
    box-shadow: -2.5px 2.5px 5px gainsboro ;
    align-items: center;
    align-content:center;
    gap: 5px;
    justify-items: center;
    justify-content: center;

    display: grid;
    grid-template-columns: 5% 70px 1fr;
    grid-template-rows: 1fr;
}
.paginaSelecao header h1 {
    grid-column: 3;
    

    text-align: center;
    justify-self: flex-end;
    margin-right: 40px;
    align-self: center;
    -webkit-text-stroke-width: 1.5px;
    color: rgb(211, 211, 211); 
    font-size: 2.5em;
    -webkit-text-stroke-color:black  ;
         padding: 5px;
    border-radius: 5px;
    height: auto;
}
.paginaSelecao header button{
    margin-left: 40px;
    width: 100%;
    height: 50px;
    background-color: rgb(211, 211, 211); ;
    color: black;
}
.mapa{
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    min-height:70%s;
    height: auto;
    padding: 0%;
    margin: 0%;
    max-width: 500px;
    display: grid;
    align-items: flex-start;
    justify-content: center;
    grid-template-columns: 1fr 50% 1fr;
    grid-template-rows: repeat(3,1fr);
    
    margin-bottom: 50px;
}
.onetothree{
    grid-row-start: 1;
    grid-row-end: 3;
    width: 70%;
    justify-self: center;
    align-self: center;
    height: 50%;
    background: url('../../../Assets/traçejado.png');
    background-size: cover;
    align-self: center  ; 
    justify-self: flex-end;       
    background-repeat: no-repeat;
    mix-blend-mode: multiply;
}
.twotothree{
    grid-column: 3;
    grid-row-start: 2;
    grid-row-end: 4;
    width: 70%;
    justify-self: flex-start;
    align-self: center;
    height: 50%;
    background: url('../../../Assets/traçejado.png');
    background-size: cover;
    align-self: center  ; 
    transform: scale(-1,1);
    justify-self: flex-start;       
    background-repeat: no-repeat;
    mix-blend-mode: multiply;

}
.mapa section {
    
    flex-direction: column;
    list-style-type: none;
    display: flex;
    text-align: center;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;

}


.seletorDeFase {
    justify-self: center;
    align-self: center;
    grid-column: 2;
    width: 100%;
    height: 70%;
    z-index: 2;
    aspect-ratio: 1/1;
    background-size: cover;
    background-position: center;
    border-radius: 5%;
    border: 10px solid black;
}
.Concluida{
    
    filter: grayscale(100%);
}
.fase0{
    background-image: url('../../../Assets/Cenarios/1.png');
}
.fase1{
    background-image: url('../../../Assets/Cenarios/2.png');
}
.fase2{
    background-image: url('../../../Assets/Cenarios/3.png');
}

#Bloqueio{
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;;
    width: 80%;
    max-width: 500px;
    height: auto;
    padding: 20px;
    position: absolute;
    z-index: 100;
    align-self: center;
    background-color: #6a6e70;
    opacity: 1;
    color: gainsboro;
    
    background-image:  repeating-linear-gradient(45deg, #5f6364 25%, transparent 25%, transparent 75%, #5f6364 75%, #5f6364), repeating-linear-gradient(45deg, #5f6364 25%, #6a6e70 25%, #6a6e70 75%, #5f6364 75%, #5f6364);
    background-position: 0 0, 10px 10px;
    background-size: 5px 5px;
    border: solid black 5px;
    border-radius: 5px;
    box-shadow: 5px 5px 15px rgb(39, 38, 38);
    text-align: center;
}

#Bloqueio button {
    width: 60%;
    max-width: 180px;
    height: 60px;
    border: 5px solid black;
    
    border-radius: 5px;
}

.seta {
    max-height: 55px;
}