.Lore{
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #6a6e70;
opacity: 1;
background-image:  repeating-linear-gradient(45deg, #5f6364 25%, transparent 25%, transparent 75%, #5f6364 75%, #5f6364), repeating-linear-gradient(45deg, #5f6364 25%, #6a6e70 25%, #6a6e70 75%, #5f6364 75%, #5f6364);
background-position: 0 0, 10px 10px;
background-size: 5px 5px;
    z-index: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
}
.fadein{
    animation: fadein 1s;
}
.fadeout{
    animation: aaa 0.5s;
}

.background{
    position: absolute;
    width: 100%;
    top: 0%;
    left: 0%;
    height: 100vh;
    background-color: rgba(250, 250, 250);
    z-index: 15;
}
.swiper{
    grid-row: 2;
    grid-column: 1;
    width: 80%;
    max-width: 500px;
    height: 85%;
    max-height: 600px;
    background-color: white;
    border: 5px solid black;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    justify-items: center;
    border-radius: 15px;

    align-items: center;
}
.slide section{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.slide section img{
    width: 60%;
    height: 50%;
    border-radius: 5px;
}
.small{
    width: 25% !important;
    height: 20% !important;

}
.slide section .text{
    width: 70%;
    background-color: #cfd8dd;
    border: 5px solid black;
    border-radius: 5px;
    box-shadow: 5px 5px 3px gainsboro;
    
    padding: 10px;
}

.slide section button{
    width: auto;
    height: auto;
    background-color: #d1e0f0;
    border: 5px solid black;
    padding: 10px;
    border-radius: 15px;

    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    animation: infinite 1s bounce;
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
}
@keyframes fadein {
    from {
        opacity: 0;

    }
    to {
        opacity: 1;

    }
}@keyframes aaa {
    from {
        transform: scale(1);

    }
    to {
        transform: scale(0);

    }
}
