@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&display=swap');

main{
    display: grid;
    grid-template-rows: 3fr 1fr;
    grid-template-columns: 1fr;
    width: 100vw;
    height: 100vh;
   
}
button{
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}
  
body{
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    display: flex;  
    justify-content: 0;
    width: 100%;
    height: 100%;

    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight:15px;
    font-style: normal;
}
.cenario{
    display:grid;
    justify-content: center;
    align-items: center;
    
}
.jogador{
    width: 20vw;
    aspect-ratio: 2/3;
    display: flex   ;
    justify-content: center;
    align-items: stretch;

}
.jogador img{
    width: 100%;
}
.inputArea{
    grid-row: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   border-top: 5px solid black;
   background-color: whitesmoke;

    
}
.inputArea:active{
    border-top: 5px solid gray;
    background-color: gray;
}