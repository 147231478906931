.final {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color:rgba(0, 0, 0, 0.8);
    gap: 20%
    }
.final h1{
    font-size: 3em !important;
    color: white;
}
.botoes {
    display: flex;
 
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.final h1{
    width: auto;
    text-align: center;
    font-size: 35px;
    padding: 10px;
    color: whitesmoke;
    border-radius: 10px;

}
.botoes div {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.icone {
    width: 80px;
    height: 80px;
    border-radius: 20px;

    background-color: rgba(189, 189, 189, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: white;
    margin: 10px;
}

.final p{
    background-color: rgba(189, 189, 189, 1);
    min-width: 80px;
    padding: 10px;
    text-align: center;
    font-size: 1em;
    color: black;
    border-radius: 5px;
    border: 5px solid rgb(112, 112, 112);
}

.final p:active{
    position: relative;
    color: white;
    background-color: rgba(112, 112, 112, 1);

}


.backgroundFim{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;
}