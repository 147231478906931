.StartScreen{
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    align-items: center;}
.StartScreen h1{
    font-size: 3em;
    color: black;
    margin-bottom: 40px;
    animation: bounce 1.5s infinite;
    text-align: center;
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
}
.StartScreen button{
    width: 60%;
    max-width: 180px;
    height: 60px;
    border: 5px solid black;
    background-color: rgba(189, 189, 189, 1);
    box-shadow: 5px 5px 5px gray;
    border-radius: 5px;
}
.StartScreen button:active{
    background-color:#d1e0f0;
}