.SinglePlayerGame{
    
    width: 100vw;
    height: 100vh;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    animation: fadein 3s;

    display: grid;
    justify-items: start;
    align-content: center;

    background: #85e1fd21;
    grid-template-rows: 80px calc(100vh - 80px);
    grid-template-columns: 1fr;
    
}
.Contador{
    grid-row-start: 1;
    grid-row-end:4;
    grid-column-start: 1;
    grid-column-end: 4;
    width: 100%;
    height: 100%;
    z-index: 10;
    backdrop-filter: blur(15px);
    z-index: 11;

    display: flex;
    align-items: center;
    justify-content: center;
}
.Contador div{
    width: 50%;
    height: 20%;
    font-size: 30pt;
    color: white;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}
.GameArea{
    overflow: hidden;

    display: grid;
    grid-template-rows: 2fr 1fr;

    width: 100%;
    height: 90%;
    gap: 10px;
    
    flex-direction: column;
    justify-items: center;
    align-items: center;
    
}
.GameArea .Game { 
    background-color: white;
    border: 5px solid black;
    box-shadow: 5px 5px 15px gray;

    justify-items: center;
    align-items: center;

    gap: 20px;
    width: 80%;
    max-width: 500px;
    aspect-ratio: 9/9;
    flex-wrap: wrap;
    border-radius: 5px;

    background: white;
   

    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
}

  
.GameCenario1{
    background: url(/src//Assets/Cenarios/1.png) !important;  
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    

}
.GameCenario1::before {
    content: "";
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-color: black;
    z-index: 1;
  }
  
 
.GameCenario2{
    background: url(/src//Assets/Cenarios/2.png) !important;  

    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}
.GameCenario2::before {
    content: "";
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-color: black;
    z-index: 1;
  }
.GameCenario3{

    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}
.GameCenario3::before {
    content: "";
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-color: black;
    z-index: 1;
  }
.GameArea .GameControls{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%    ;
    gap: 20px;
    max-width: 800px;

    flex-wrap: wrap;
}
.GameArea .GameControls h1{
    margin: 0%;
    font-size: 40px;

    background-color: #85e1fd;

}
.GameControlsButtons{
    border-radius: 5px;
    border: 5px solid black;

    background-color: #6a6e70;
    opacity: 0.8;
    background-image:  repeating-linear-gradient(45deg, #5f6364 25%, transparent 25%, transparent 75%, #5f6364 75%, #5f6364), repeating-linear-gradient(45deg, #5f6364 25%, #6a6e70 25%, #6a6e70 75%, #5f6364 75%, #5f6364);
    background-position: 0 0, 10px 10px;
    background-size: 5px 5px;
    box-shadow: 5px 5px 15px gray;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width:80%;
    height: 100%;
    max-height: 120px;
    flex-wrap: wrap;

}
.IndicadorVisual{
    max-height: 60px;
    height: 90%;
    width: 20%;
    border-radius: 4px;
    display: flex;
    justify-content: center     ;
    align-items: center;
    max-width: 80px;
    margin: -5px;
    border: 5px solid black;
}
.IndicadorVisual:active::before{
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 5px solid black;
    background-color: rgb(37, 37, 37);
    opacity: 1;
    background-size: cover;
    background-position: center;
    animation: linear 1s gohigh;
}
@keyframes gohigh{
    0%{
        transform: translateY(0px);
    }
    
    100%{
        transform: translateY(-500px);
        opacity: 0;
    }

}
.SinglePlayerGame header{
    

    width: 100%;
    height: 100%;
    background-color: #6a6e70;
    opacity: 0.8;
    background-image:  repeating-linear-gradient(45deg, #5f6364 25%, transparent 25%, transparent 75%, #5f6364 75%, #5f6364), repeating-linear-gradient(45deg, #5f6364 25%, #6a6e70 25%, #6a6e70 75%, #5f6364 75%, #5f6364);
    background-position: 0 0, 10px 10px;
    background-size: 5px 5px;
    border-bottom: 8px solid black;

    box-shadow: -2.5px 2.5px 5px gainsboro ;
    align-items: center;
    align-content:center;
    gap: 5px;
    justify-items: center;
    justify-content: center;

    display: grid;
    grid-template-columns: 5% 70px 1fr;
}
.SinglePlayerGame header section{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    grid-column: 3;
    gap: 5px;
    width: 100%;
    height:100%;
    margin: 0%;
    padding: 0%;    
    
    flex-direction: column;
}
.SinglePlayerGame header section .lifebar{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    grid-column: 3;
    gap: 3px;
    width: auto;
    height:auto;
    margin: 0%;
    margin-right: 10%;
    border: 2px solid black;
    padding-left: 5px;
    padding-right: 5px;

    border-radius: 5px;
    background-color: rgba(189, 189, 189, 0.6);
    flex-direction: row;
}
.heart{
    width: 30px;
    height: 30px;
    background: url('../../Assets/coração.png');

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.brokenHeart{
    width: 30px;
    height: 30px;
    background: url('../../Assets/coraçãoVazio.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.SinglePlayerGame header section h1{
    margin: 0%;
    padding: 0%;
    margin-right: 10%;
   

    border-bottom: 2px solid black;
    font-size: large;
    -webkit-text-stroke-width: 0.5px;
    color: black; 
    -webkit-text-stroke-color:black  ;
    text-align: center;

}
.SinglePlayerGame header button{
    align-self: center;
    grid-row: 1;
    grid-column: 2;
    width: 100%;
    height: 100%;
    font-size: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 5px solid black;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    background-color: rgba(189, 189, 189, 1);


}
