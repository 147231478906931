.fundoCreditos {
    display: flex;
    justify-content: center;
    height: auto;
    min-height: 100vh;
    width: 100vw;
    background-image:  repeating-linear-gradient(45deg, #5f6364 25%, transparent 25%, transparent 75%, #5f6364 75%, #5f6364), repeating-linear-gradient(45deg, #5f6364 25%, #6a6e70 25%, #6a6e70 75%, #5f6364 75%, #5f6364);
    background-position: 0 0, 10px 10px;
    background-size: 5px 5px;
    overflow: scroll;
}

.creditos {
    
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    width: 80vw;
    border-left: 5px solid black;
    border-right: 5px solid black;
}

.creditos p {
    box-shadow: 5px 5px 5px gray;

    border: 5px solid black;
    border-radius: 10px;
    width: 80%;
    margin: 0;
    margin-bottom: 10px;
    max-width: 500px;
    background-color: rgba(189, 189, 189, 1);
}
.creditos ol{
    padding: 0%;
    min-width: 200px;
    padding-bottom: 10px;
    text-align: center  ;
    list-style: none;
}
.creditos  img{
    padding: 0%;
    min-width: 200px;
    border: 5px solid black;
    border-radius: 5px;
    width: 200px;
    height: 200px;
    text-align: center  ;
    list-style: none;
}
.creditos .botaoVoltar {
    width: 60%;
    max-width: 180px;
    height: 60px;
    border: 5px solid black;
    background-color: rgba(189, 189, 189, 1);
    box-shadow: 5px 5px 5px gray;
    border-radius: 5px;
    margin-top: 50px;

    margin-bottom: 50px;
}